.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  border-bottom: 1px #e3e3e3 solid;
}

.logo {
  border-radius: 12px;
}

.title-container {
  margin-left: 16px;
}

.title,
.subtitle {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.container {
  padding: 16px 16vw;
}

.footer {
  padding: 24px 0;
}

.footer-links {
  display: flex;
}

.footer-link:not(:first-child) {
  margin-left: 16px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
