.home-title {
    margin: 24px 0;
}

.promo-mobile, .promo-desktop {
    position: relative;
}

.screenshot {
    width: 340px;
    margin: 64px 0;
    border-radius: 16px;
}

.promo-mobile {
    display: block !important;
}

.promo-desktop {
    display: none !important;
}

.project {
    position: absolute;
    top: -25px;
    left: 45px;
}

.tasks {
    position: absolute;
    top: 224px;
    left: 20px;
}

.docs {
    position: absolute;
    top: 322px;
    left: -24px;
}

.events {
    position: absolute;
    bottom: 62px;
    left: 20px;
}

@media (min-width: 769px) {
    .screenshot {
        width: 360px;
        margin: 24px 0;
        border-radius: 16px;
    }
    .promo-mobile {
        display: none !important;
    }
    .promo-desktop {
        display: block !important;
    }
    .project {
        position: absolute;
        top: 32px;
        left: -325px;
    }
    .tasks {
        position: absolute;
        top: 154px;
        left: 325px;
    }
    .docs {
        position: absolute;
        bottom: 248px;
        left: -325px;
        height: 104px !important;
    }
    .events {
        position: absolute;
        bottom: 64px;
        left: 325px;
    }
}

/* talk bubble contents */

.talktext {
    padding: 1em;
    text-align: left;
    line-height: 1.5em;
}

.talktext p {
    /* remove webkit p margins */
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    font-size: 14px;
}

/* CSS talk bubble */

.talk-bubble {
    margin: 40px;
    display: inline-block;
    width: 280px;
    height: auto;
    background-color: #bbf1f1;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.1);
}

.border {
    border: 8px solid #666;
}

.round {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
}

/* Right triangle placed top left flush. */

.tri-right.border.left-top:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -40px;
    right: auto;
    top: -8px;
    bottom: auto;
    border: 32px solid;
    border-color: #666 transparent transparent transparent;
}

.tri-right.left-top:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -20px;
    right: auto;
    top: 0px;
    bottom: auto;
    border: 22px solid;
    border-color: #bbf1f1 transparent transparent transparent;
}

/* Right triangle, left side slightly down */

.tri-right.border.left-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -40px;
    right: auto;
    top: 30px;
    bottom: auto;
    border: 20px solid;
    border-color: #666 #666 transparent transparent;
}

.tri-right.left-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -20px;
    right: auto;
    top: 38px;
    bottom: auto;
    border: 12px solid;
    border-color: #bbf1f1 #bbf1f1 transparent transparent;
}

/*Right triangle, placed bottom left side slightly in*/

.tri-right.border.btm-left:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -8px;
    right: auto;
    top: auto;
    bottom: -40px;
    border: 32px solid;
    border-color: transparent transparent transparent #666;
}

.tri-right.btm-left:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 0px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 22px solid;
    border-color: transparent transparent transparent #bbf1f1;
}

/*Right triangle, placed bottom left side slightly in*/

.tri-right.border.btm-left-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 30px;
    right: auto;
    top: auto;
    bottom: -40px;
    border: 20px solid;
    border-color: #666 transparent transparent #666;
}

.tri-right.btm-left-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 38px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 12px solid;
    border-color: #bbf1f1 transparent transparent #bbf1f1;
}

/*Right triangle, placed bottom right side slightly in*/

.tri-right.border.btm-right-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 30px;
    bottom: -40px;
    border: 20px solid;
    border-color: #666 #666 transparent transparent;
}

.tri-right.btm-right-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 38px;
    bottom: -20px;
    border: 12px solid;
    border-color: #bbf1f1 #bbf1f1 transparent transparent;
}

/*
	left: -8px;
  right: auto;
  top: auto;
	bottom: -40px;
	border: 32px solid;
	border-color: transparent transparent transparent #666;
	left: 0px;
  right: auto;
  top: auto;
	bottom: -20px;
	border: 22px solid;
	border-color: transparent transparent transparent #bbf1f1;

/*Right triangle, placed bottom right side slightly in*/

.tri-right.border.btm-right:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -8px;
    bottom: -40px;
    border: 20px solid;
    border-color: #666 #666 transparent transparent;
}

.tri-right.btm-right:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 0px;
    bottom: -20px;
    border: 12px solid;
    border-color: #bbf1f1 #bbf1f1 transparent transparent;
}

/* Right triangle, right side slightly down*/

.tri-right.border.right-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -40px;
    top: 30px;
    bottom: auto;
    border: 20px solid;
    border-color: #666 transparent transparent #666;
}

.tri-right.right-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -20px;
    top: 38px;
    bottom: auto;
    border: 12px solid;
    border-color: #bbf1f1 transparent transparent #bbf1f1;
}

/* Right triangle placed top right flush. */

.tri-right.border.right-top:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -40px;
    top: -8px;
    bottom: auto;
    border: 32px solid;
    border-color: #666 transparent transparent transparent;
}

.tri-right.right-top:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -20px;
    top: 0px;
    bottom: auto;
    border: 20px solid;
    border-color: #bbf1f1 transparent transparent transparent;
}